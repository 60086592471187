import './assets/main.css';

import 'virtual:uno.css';

import { createHead } from '@unhead/vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { DataLoaderPlugin } from 'unplugin-vue-router/data-loaders';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { router } from './router';

import Aura from '@primevue/themes/aura';
import zhCN from 'primelocale/zh-CN.json';
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';

/* https://github.com/intlify/bundle-tools/tree/main/packages/unplugin-vue-i18n#static-bundle-importing
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages';

async function init() {
  if ((import.meta.env.MODE === 'development' || 1 === 1) && 0) {
    // TODO: https://github.com/hu3dao/vite-plugin-debug/
    // https://eruda.liriliri.io/zh/docs/#快速上手
    await import('eruda').then(({ default: eruda }) => {
      eruda.init({
        defaults: {
          transparency: 0.9,
        },
      });
      // eruda.show();
    });
  }

  console.debug(`messages :>> `, messages);
  const app = createApp(App)
    .use(createHead())
    .use(createPinia().use(piniaPluginPersistedstate))
    // Register the plugin before the router
    .use(DataLoaderPlugin, { router })
    // adding the router will trigger the initial navigation
    .use(router)
    .use(
      // https://vue-i18n.intlify.dev/guide/essentials/started.html#registering-the-i18n-plugin
      createI18n({
        legacy: false, // you must set `false`, to use Composition API
        locale: navigator.language,
        messages,
      }),
    )
    .use(PrimeVue, {
      locale: zhCN['zh-CN'], // usePrimeVue().config.locale
      theme: { preset: Aura },
      options: {
        prefix: 'p',
        darkModeSelector: 'system',
        cssLayer: false,
      },
    });
  app.config.globalProperties.$__DEV__ = $__DEV__;
  app.mount('#app');
}

init();
