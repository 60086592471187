const fakeModuleList = window.__VITE__PLUGIN__FAKE__SERVER__.fakeModuleList;
					const pathToRegexp = window.__VITE__PLUGIN__FAKE__SERVER__.pathToRegexp;
					const match = pathToRegexp.match ?? pathToRegexp.default.match;
					window.__VITE__PLUGIN__FAKE__SERVER__.xhook.before(async function(req, callback) {
						function sleep(time) {
	return new Promise((resolve) => {
		const timer = setTimeout(() => {
			resolve(timer);
			clearTimeout(timer);
		}, time);
	});
}
						function tryToJSON(str) {
	let result = "";
	try {
		result = JSON.parse(str);
	}
	catch {
		result = str;
	}
	return result;
}
						async function simulateServerResponse(req = {}, fakeModuleList = [], config = {}) {
	const {
		match,
		basename = "",
		defaultTimeout = 0,
		globalResponseHeaders = {},
	} = config;

	/**
	 * Join two paths into a complete path
	 */

	function joinPathname(a, b) {
		const aPathname = new URL(a, "http://localhost:5173/").pathname;
		const bPathname = new URL(b, "http://localhost:5173/").pathname;
		return aPathname.endsWith("/") ? aPathname.slice(0, -1) + bPathname : aPathname + bPathname;
	}

	if (req.url) {
		const instanceURL = new URL(req.url, "http://localhost:5173/");

		// https://nodejs.org/api/url.html#urlpathname
		// Invalid URL characters included in the value assigned to the pathname property are percent-encoded
		const pathname = instanceURL.pathname;

		const matchRequest = fakeModuleList.find((item) => {
			if (!pathname || !item || !item.url) {
				return false;
			}
			const method = item.method ?? "GET";
			const reqMethod = req.method ?? "GET";
			if (method.toUpperCase() !== reqMethod.toUpperCase()) {
				return false;
			}
			const realURL = joinPathname(basename, item.url);
			return !!match(realURL)(pathname);
		});
		if (matchRequest) {
			const {
				response,
				rawResponse,
				timeout = defaultTimeout,
				statusCode,
				statusText,
				url,
				headers: responseHeaders = {},
			} = matchRequest;

			if (timeout) {
				await sleep(timeout);
			}

			const joinedUrl = joinPathname(basename, url);
			const urlMatch = match(joinedUrl, { encode: encodeURI });

			const searchParams = instanceURL.searchParams;
			const query = {};
			for (const [key, value] of searchParams.entries()) {
				if (Object.prototype.hasOwnProperty.call(query, key)) {
					const queryValue = query[key];
					if (Array.isArray(queryValue)) {
						queryValue.push(value);
					}
					else {
						query[key] = [queryValue, value];
					}
				}
				else {
					query[key] = value;
				}
			}

			let params = {};

			if (pathname) {
				const matchParams = urlMatch(pathname);
				if (matchParams) {
					params = matchParams.params;
				}
			}

			return {
				response,
				rawResponse,
				timeout,
				statusCode: statusCode ?? 200,
				statusText,
				url: req.url,
				query,
				params,
				responseHeaders: new Headers({ ...globalResponseHeaders, ...responseHeaders }),
			};
		}
	}
}
						const STATUS_CODES = {
  "100": "Continue",
  "101": "Switching Protocols",
  "102": "Processing",
  "103": "Early Hints",
  "200": "OK",
  "201": "Created",
  "202": "Accepted",
  "203": "Non-Authoritative Information",
  "204": "No Content",
  "205": "Reset Content",
  "206": "Partial Content",
  "207": "Multi-Status",
  "208": "Already Reported",
  "226": "IM Used",
  "300": "Multiple Choices",
  "301": "Moved Permanently",
  "302": "Found",
  "303": "See Other",
  "304": "Not Modified",
  "305": "Use Proxy",
  "307": "Temporary Redirect",
  "308": "Permanent Redirect",
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Failed",
  "413": "Payload Too Large",
  "414": "URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Range Not Satisfiable",
  "417": "Expectation Failed",
  "418": "I'm a Teapot",
  "421": "Misdirected Request",
  "422": "Unprocessable Entity",
  "423": "Locked",
  "424": "Failed Dependency",
  "425": "Too Early",
  "426": "Upgrade Required",
  "428": "Precondition Required",
  "429": "Too Many Requests",
  "431": "Request Header Fields Too Large",
  "451": "Unavailable For Legal Reasons",
  "500": "Internal Server Error",
  "501": "Not Implemented",
  "502": "Bad Gateway",
  "503": "Service Unavailable",
  "504": "Gateway Timeout",
  "505": "HTTP Version Not Supported",
  "506": "Variant Also Negotiates",
  "507": "Insufficient Storage",
  "508": "Loop Detected",
  "509": "Bandwidth Limit Exceeded",
  "510": "Not Extended",
  "511": "Network Authentication Required"
};

						function headersToObject(headers) {
							const headersObject = {};
							for (const [name, value] of headers.entries()) {
								headersObject[name] = value;
							}
							return headersObject;
						}

						const responseResult = await simulateServerResponse(req, fakeModuleList, {
							match,
							basename: "fake-api",
							defaultTimeout: undefined,
							globalResponseHeaders: {}
						});
						if (responseResult) {
							const {
								response,
								statusCode,
								statusText: responseStatusText = STATUS_CODES[statusCode],
								url,
								query,
								params,
								responseHeaders,
							} = responseResult ?? {};
							const statusText = true ? "" : responseStatusText;
							const responseIsFunction = typeof response === "function";
							const requestHeaders = {};
							for (const key in req.headers) {
								requestHeaders[key.toLowerCase()] = req.headers[key];
							}
							const fakeResponse = !responseIsFunction || await Promise.resolve(
								response({ url, body: tryToJSON(req.body), rawBody: req.body, query, params, headers: requestHeaders })
							);
							if(req.isFetch) {
								if (typeof fakeResponse === "string") {
									if (!responseHeaders.get("Content-Type")) {
										responseHeaders.set("Content-Type", "text/plain");
									}
									callback(new Response(
										responseIsFunction ? fakeResponse : null,
										{
											statusText,
											status: statusCode,
											headers: headersToObject(responseHeaders),
										}
									));
								} else {
									if (!responseHeaders.get("Content-Type")) {
										responseHeaders.set("Content-Type", "application/json");
									}
									callback(new Response(
										responseIsFunction ? JSON.stringify(fakeResponse, null, 2) : null,
										{
											statusText,
											status: statusCode,
											headers: headersToObject(responseHeaders),
										}
									));
								}
							} else {
								const dataResponse = responseIsFunction ? { data: fakeResponse } : {};
								if(!req.type || req.type.toLowerCase() === "text") {
									if (!responseHeaders.get("Content-Type")) {
										responseHeaders.set("Content-Type", "text/plain");
									}
									callback({
										statusText,
										status: statusCode,
										text: fakeResponse,
										...dataResponse,
										headers: headersToObject(responseHeaders),
									});
								} else if (req.type.toLowerCase() === "json") {
									if (!responseHeaders.get("Content-Type")) {
										responseHeaders.set("Content-Type", "application/json");
									}
									callback({
										statusText,
										status: statusCode,
										...dataResponse,
										headers: headersToObject(responseHeaders),
									});
								} else if (req.type.toLowerCase() === "document") {
									if (!responseHeaders.get("Content-Type")) {
										responseHeaders.set("Content-Type", "application/xml");
									}
									const parser = new DOMParser();
									const xmlDoc = parser.parseFromString(fakeResponse,"application/xml");
									callback({
										statusText,
										status: statusCode,
										xml: xmlDoc,
										data: xmlDoc,
										headers: headersToObject(responseHeaders),
									});
								} else {
									// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
									// "arraybuffer" | "blob"
									callback({
										statusText,
										status: statusCode,
										...dataResponse,
										headers: headersToObject(responseHeaders),
									});
								}
							}
							if (true){
								const requestMethod = req.method ? req.method.toUpperCase() : "GET";
								console.log("%c request invoke" + " %c" + requestMethod + " " + req.url, "color: green", "color: blue");
							}
						} else {
							// next external URL
							callback();
						}
					});